import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Slides from './Slides'

import 'react-tabs/style/react-tabs.css';

class SlideTabs extends React.Component {

  render() {
    // sort so nulls come last- see: https://stackoverflow.com/questions/43819867/sort-an-array-of-numbers-so-that-null-values-come-last
    const slides = this.props.slides.sort((a, b) =>
                                          (b.thumbnail_display_order != null) - (a.thumbnail_display_order!= null) || a.thumbnail_display_order - b.thumbnail_display_order
                                          )
    const matchingSlides = slides.filter(slide => {
      return this.match(slide)
    })

    return (
      <div className='container'>
        <Tabs className="mt-3">
          <TabList>
            <Tab>Matching Slides ({matchingSlides.length})</Tab>
            <Tab>All Slides ({slides.length})</Tab>
          </TabList>
          <TabPanel>
            <div className='row'>
              <Slides slides={matchingSlides}/>
            </div>
          </TabPanel>
          <TabPanel>
            <div className='row'>
              <Slides slides={slides}/>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    )
  }

  match(slide) {
    const searchParams = this.props.state.searchParams
    const processings = searchParams.processingMethod || []
    const areas = searchParams.processingArea || []
    const pairs = slide.sl_processings || []

    for (let pair of pairs) {
      const [method, area] = pair.split('|')
      if ((processings.length === 0 || processings.includes(method)) && 
        (areas.length === 0 || areas.includes(area)))
      {
        return true
      }
    }
    return (processings.length === 0 && areas.length === 0)
  }
}

export default SlideTabs
