const initialState = {
  email: '',
  name: '',
  affiliation: '',
  text: ''
}

const requestMail = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_REQUEST_PARAMS':
      switch (action.key) {
        case 'email':
        case 'name':
        case 'affiliation':
        case 'text':
          const newState = { ...state }
          newState[action.key] = action.value
          return newState
        default:
          console.error('ERROR reducers/requestMail invalid action.key', action.key)
          return state
      }
    case 'RESET_REQUEST_PARAMS':
      return { ...initialState }
    default:
      return state
  }
};

export default requestMail
