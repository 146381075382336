import React from 'react'

const buildProcessingsSection = slide => {
  let result = []
  const processings = slide.sl_processings || []

  for (let i = 0; i < processings.length; ++i) {
    let [method, area] = processings[i].split('|')
    result.push(
      <p key={'processing-' + i} className="card-text">
        <span className="label">Slide treatment:</span> {method}<br/>
        <span className="label">Location:</span> {area}
      </p>
    )
  }
  return result
}

const Slide = (props) => {
  const slide = props.slide
  const thumbnailURL = slide.thumbnail_url
  const altText = `Slide ${slide.aperio_id}`

  return (
    <div className="slide col p-2 m-2 macbrain-card" style={{minWidth: 360}}>
      <div className="row">
        <div className="col" style={{maxWidth: 140}}>
          <img width="150" src={thumbnailURL} alt={altText}/>
        </div>
        <div className="col">
          {buildProcessingsSection(slide)}
        </div>
      </div>
    </div>
  )
}

export default Slide
