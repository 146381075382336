import { connect } from 'react-redux'

import {
  updateSearchParams,
  resetSearchParams,
  updateSearchResult,
  updateProjects,
  updateSlides,
  updateRequestParams,
  resetRequestParams,
  updateAlert,
  resetAlert
} from 'src/actions'

import App from 'src/components/App'

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
  return {
    updateSearchParams: (key, value) => {
      dispatch(updateSearchParams(key, value))
    },
    resetSearchParams: (key, value) => {
      dispatch(resetSearchParams(key, value))
    },
    updateProjects: projects => {
      dispatch(updateProjects(projects));
    },
    receivedSearchResult: result => {
      dispatch(updateSearchResult(result))
    },
    receivedSlides: (specimen, slides) => {
      dispatch(updateSlides(specimen, slides))
    },
    updateRequestParams: (key, value) => {
      dispatch(updateRequestParams(key, value))
    },
    resetRequestParams: (key, value) => {
      dispatch(resetRequestParams(key, value))
    },
    updateAlert: (type, message, expireInMS) => {
      dispatch(updateAlert(type, message, expireInMS))
    },
    resetAlert: () => {
      dispatch(resetAlert())
    }
  }
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default AppContainer
