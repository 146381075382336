import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'

import Alert from './Alert'

class Request extends React.Component {
  constructor(props) {
    super(props)
    this.bindEvents()
  }

  render() {
    const alert = this.props.state.alert
    const hasError = (alert.type === 'error')
    const errorMessage = hasError ? alert.message : ''
    const request = this.props.state.requestMail
    this.recaptchaRef = React.createRef()

    return (
      <div className="container">
        <h2 className="my-5">Request for Access</h2>
        <form>
          <Alert state={this.props.state.alert}/>
          <div className="form-group row">
            <label htmlFor="request-name" className="label col-sm-2 col-lg-1 col-form-label">Name<sup className="required">*</sup></label>
            <div className="col-sm-10 col-lg-11">
              <input id="request-name" className="form-control"
                placeholder="Name of requester"
                value={request.name} onChange={this.onChange('name')} required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="request-email" className="label col-sm-2 col-lg-1 col-form-label">Email<sup className="required">*</sup></label>
            <div className="col-sm-10 col-lg-11">
              <input id="request-email" className="form-control"
                placeholder="Email address of requester"
                value={request.email} onChange={this.onChange('email')} required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="request-affiliation" className="label col-sm-2 col-lg-1 col-form-label">Affiliation</label>
            <div className="col-sm-10 col-lg-11">
              <input id="request-affiliation" className="form-control"
                value={request.affiliation} onChange={this.onChange('affiliation')}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="request-msg" className="label col-sm-2 col-lg-1 col-form-label">Request<sup className="required">*</sup></label>
            <div className="col-sm-10 col-lg-11">
              <p>
                Please tell us briefly about yourself and your project and your need.
                Brain materials may be accessed either on-site or remotely via online download of digitized images.
              </p>
              <textarea id="request-msg" className="form-control" rows="8"
                value={request.text} onChange={this.onChange('text')} required
              />
            </div>
          </div>
          <ReCAPTCHA
            className="mt-4"
            ref={this.recaptchaRef}
            sitekey={process.env.RECAPTCHA_SITE_KEY}
            render="explicit"
            size="normal"
            onChange={this.onRecaptchaChange}
          />
          <div className="form-group mt-3 mb-5">
            <button type="submit"  className="btn btn-secondary mr-3" onClick={this.onCancel}>Cancel</button>
            <button type="submit" className="btn btn-primary" onClick={this.onSend}>Send</button>
          </div>
        </form>
      </div>
    )
  }

  bindEvents() {
    this.onCancel = event => {
      event.preventDefault()
      this.props.resetAlert()
      this.props.resetRequestParams()
      this.props.history.goBack()
    }

    this.onSend = event => {
      event.preventDefault()
      const success = this.validate()

      if (success) {
        const recaptchaValue = this.recaptchaRef.current.getValue()
        const mailParams = this.props.state.requestMail
        const payload = {
          mailParams,
          recaptchaValue
        }
        return axios.post('/api/mail', payload).then(response => {
          console.log('Request response:', response)
          this.props.updateAlert('info', 'Request has been sent.')
          this.props.resetRequestParams()
          this.props.history.goBack()
          return response.data
        }).catch(error => {
          if (error.response) {
            console.log('ERROR failed to send request', error.response)
            if (error.response.data.error_code === 1) {
              this.props.updateAlert('error', 'Please complete the "I\'m not a robot" challenge at the bottom first.')
            } else {
              this.props.updateAlert('error', 'An unknown error occured sending the email. Please try again.')
            }
          }
        })
      }
    }

    this.onRecaptchaChange = () => {
      console.log('onRecaptchaChange')
      const value = this.recaptchaRef.current.getValue()
      console.log('value:', value)
    }

    this.onChange = paramsKey => {
      return event => {
        this.props.updateRequestParams(paramsKey, event.target.value)
      }
    }
  }

  validate() {
    const state = this.props.state.requestMail
    let success = this.validateName(state)
    success = success && this.validateEmail(state)
    success = success && this.validateText(state)
    return success
  }

  validateEmail(state) {
    const email = state.email ? state.email.trim() : ''

    if (email.length > 0) {
      if (!email.match(/\S+@\S+\.\S+/)) {
        this.props.updateAlert('error', 'Invalid email. Did you type your email address correctly?')
        return false;
      }
    } else {
      this.props.updateAlert('error', 'Please enter your email address.')
      return false
    }
    return true
  }

  validateName(state) {
    const name = state.name ? state.name.trim() : ''
    if (name.length === 0) {
      this.props.updateAlert('error', 'Please enter you name.')
      return false
    }
    return true
  }

  validateText(state) {
    const text = state.text ? state.text.trim() : ''
    if (text.length === 0) {
      this.props.updateAlert('error', 'Please fill in the "Request" field.')
      return false
    }
    return true
  }
}

export default Request
