import axios from 'axios'

const initAxios = () => {
  const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'
}

const globalInit = () => {
  initAxios()
}

export { globalInit }
