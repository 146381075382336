import React from 'react'
import Select from 'react-select'

import getDataSource from 'src/services/DataSource'
import queryString from 'query-string'

import searchOptions from 'src/config/searchOptions'

class SearchForm extends React.Component {
  constructor() {
    super()
    this.setupEventHandlers()
  }

  componentDidMount() {
    this.getProjects().then(projects => {
      this.extractSearchParamsFromURL()
      this.search()
    })
  }

  render() {
    const searchParams = this.props.state.searchParams
    const collectionOptions = this.getOptionsByValues(this.getProjectOptions(), searchParams.collection || [])
    const treatmentOptions = this.getOptionsByValues(searchOptions.treatment, searchParams.treatmentMethod || [])
    const locationOptions = this.getOptionsByValues(searchOptions.location, searchParams.treatmentLocation || [])
    const treatmentAgeType = searchParams.treatmentAgeType || 'any'
    const treatmentAgeMin = this.numText(searchParams.treatmentAgeMin)
    const treatmentAgeMax = this.numText(searchParams.treatmentAgeMax)
    const sacrificeAgeType = searchParams.sacrificeAgeType || 'any'
    const sacrificeAgeMin = this.numText(searchParams.sacrificeAgeMin)
    const sacrificeAgeMax = this.numText(searchParams.sacrificeAgeMax)
    const processingOptions = this.getOptionsByValues(searchOptions.processing, searchParams.processingMethod || [])
    const areaOptions = this.getOptionsByValues(searchOptions.area, searchParams.processingArea || [])

    return (
      <div className="macbrain-section macbrain-search-pane mb-5">
        <form>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="collection-selector">Collection:</label>
                <Select
                  isMulti
                  options={this.getProjectOptions()}
                  value={collectionOptions}
                  onChange={this.onCollectionsSelect}
                  id="collection-selector"
                />
              </div>

              <div className="form-group">
                <label htmlFor="treatement-selector">Experimental Treatment:</label>
                <Select
                  isMulti
                  options={searchOptions.treatment}
                  value={treatmentOptions}
                  onChange={this.onTreatmentSelect}
                  id="treatment-selector"
                />
              </div>

              <div className="form-group">
                <label htmlFor="location-selector">Location of Experimental Treatment:</label>
                <Select
                  isMulti
                  options={searchOptions.location}
                  value={locationOptions}
                  onChange={this.onLocationSelect}
                  id="location-selector"
                />
              </div>

              <div className="form-group">
                <fieldset>
                  <legend>Age at Experiment:</legend>
                  <label htmlFor="age-experiment-from" className="mr-2">From</label>
                  <input className="form-control-inline" className="mr-2" size="3"
                    value={treatmentAgeMin} onChange={this.onTreatmentAgeFrom}></input>
                  <label htmlFor="age-experiment-to" className="mr-2">to</label>
                  <input className="form-control-inline" className="mr-4" size="3"
                    value={treatmentAgeMax} onChange={this.onTreatmentAgeTo}></input>
                  <label className="mr-2">
                    <input type="radio" name="expr-age-type" value="e" className="mr-2"
                      checked={treatmentAgeType === 'e'}
                      onChange={this.onTreatmentAgeType}/>
                    E
                  </label>
                  <label className="mr-2">
                    <input type="radio" name="expr-age-type" value="p" className="mr-2"
                      checked={treatmentAgeType === 'p'}
                      onChange={this.onTreatmentAgeType}/>
                    P
                  </label>
                  <label>
                    <input type="radio" name="expr-age-type" value="any" className="mr-2"
                      checked={treatmentAgeType === 'any'}
                      onChange={this.onTreatmentAgeType}/>
                    Any
                  </label>
                </fieldset>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="processing-selector">Histological Processing:</label>
                <Select
                  isMulti
                  options={searchOptions.processing}
                  value={processingOptions}
                  onChange={this.onProcessingSelect}
                  id="processing-selector"
                />
              </div>
              <div className="form-group">
                <label htmlFor="brain-area-selector">Brain Areas:</label>
                <Select
                  isMulti
                  options={searchOptions.area}
                  value={areaOptions}
                  onChange={this.onAreaSelect}
                  id="brain-area-selector"
                />
              </div>
              <div className="form-group">
                <fieldset>
                  <legend>Age at Sac:</legend>
                  <label htmlFor="sacrifice-age-from" className="mr-2">From</label>
                  <input id="sacrifice-age-from" className="form-control-inline mr-2" size="3"
                    value={sacrificeAgeMin} onChange={this.onSacrificeAgeFrom}></input>
                  <label htmlFor="sacrifice-age-to" className="mr-2">to</label>
                  <input id="sacrifice-age-to" className="form-control-inline mr-4" size="3"
                    value={sacrificeAgeMax} onChange={this.onSacrificeAgeTo}></input>
                  <label htmlFor="sacrifice-age-type-e" className="mr-2">
                  <input type="radio" id="sacrifice-age-type-e" name="sacrifice-age-type" className="mr-2"
                    value="e" checked={sacrificeAgeType === 'e'} onChange={this.onSacrificeAgeType} />
                    E
                  </label>
                  <label className="mr-2">
                    <input type="radio" name="sacrifice-age-type" className="mr-2"
                      value="p" checked={sacrificeAgeType === 'p'} onChange={this.onSacrificeAgeType} />
                    P
                  </label>
                  <label className="mr-2">
                    <input type="radio" name="sacrifice-age-type" className="mr-2"
                      value="adult" checked={sacrificeAgeType === 'adult'} onChange={this.onSacrificeAgeType} />
                    Adult
                  </label>
                  <label>
                    <input type="radio" name="sacrifice-age-type"
                      value="any" checked={sacrificeAgeType === 'any'} className="mr-2" onChange={this.onSacrificeAgeType}/>
                    Any
                  </label>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="form-group mt-2 mb-0">
            <button type="submit" className="btn btn-secondary mr-3" onClick={this.onClear}>Clear</button>
            <button type="submit" className="btn btn-primary" onClick={this.onSubmit}>Search</button>
          </div>
        </form>
      </div>
    )
  }

  setupEventHandlers() {
    this.onSubmit = (event => {
      event.preventDefault()
      this.props.resetAlert()
      this.search()
    }).bind(this)

    this.onClear = (event => {
      event.preventDefault()
      this.props.resetAlert()
      this.props.resetSearchParams()
    }).bind(this)

    this.onCollectionsSelect = selectedOptions => {
      this.updateSearchParamsFromOptions('collection', selectedOptions)
    }

    this.onTreatmentSelect = selectedOptions => {
      this.updateSearchParamsFromOptions('treatmentMethod', selectedOptions)
    }

    this.onLocationSelect = selectedOptions => {
      this.updateSearchParamsFromOptions('treatmentLocation', selectedOptions)
    }

    this.onTreatmentAgeFrom = event => {
      this.updateSearchParamsFromTextField('treatmentAgeMin', event)
    }

    this.onTreatmentAgeTo = event => {
      this.updateSearchParamsFromTextField('treatmentAgeMax', event)
    }

    this.onTreatmentAgeType = event => {
      const value = event.target.value

      if (value === 'any') {
        this.props.updateSearchParams({'treatmentAgeType': value,
          'treatmentAgeMin': undefined,
          'treatmentAgeMax': undefined
        })
      } else {
        this.props.updateSearchParams('treatmentAgeType', value)
      }
    }

    this.onProcessingSelect = selectedOptions => {
      this.updateSearchParamsFromOptions('processingMethod', selectedOptions)
    }

    this.onAreaSelect = selectedOptions => {
      this.updateSearchParamsFromOptions('processingArea', selectedOptions)
    }

    this.onSacrificeAgeFrom = event => {
      this.updateSearchParamsFromTextField('sacrificeAgeMin', event)
    }

    this.onSacrificeAgeTo = event => {
      this.updateSearchParamsFromTextField('sacrificeAgeMax', event)
    }

    this.onSacrificeAgeType = event => {
      const value = event.target.value

      if (value === 'any' || value === 'adult') {
        this.props.updateSearchParams({'sacrificeAgeType': value,
          'sacrificeAgeMin': undefined,
          'sacrificeAgeMax': undefined
        })
      } else {
        this.props.updateSearchParams('sacrificeAgeType', value)
      }
    }
  }

  updateSearchParamsFromOptions(key, selectedOptions) {
    const value = selectedOptions.map(option => option.value)
    this.props.updateSearchParams(key, value)
  }

  updateSearchParamsFromTextField(key, event) {
    console.log('CHANGE', key, event.target.value)
    const target = event.target
    let value = parseInt(target.value, 10)

    if (isNaN(value)) {
      value = undefined
    }
    this.props.updateSearchParams(key, value)
  }

  getProjectOptions() {
    return this.props.state.projects.map(project => {
      return {
        label: project.label,
        value: project.aperio_id
      }
    })
  }

  extractSearchParamsFromURL() {
    const params = queryString.parse(this.props.search)
    const searchParams = {}

    console.log('Extracted search params from URL:', params)
    if (params['collection']) {
      searchParams['collection'] = [parseInt(params['collection'], 10)]
    }
    this.props.updateSearchParams(searchParams)
  }

  getProjects() {
    const projects = this.props.state.projects
    return new Promise((resolve, reject) => {
      if (projects instanceof Array && projects.length > 0) {
        resolve(projects)
      } else {
        getDataSource().getProjects().then(projects => {
          this.props.updateProjects(projects)
          resolve(projects)
        })
      }
    })
  }

  search() {
    this.props.updateSearchParams('start', 0)
    setTimeout(() => {
      getDataSource().search(this.props.state.searchParams).then(result => {
        this.props.state.receivedSearchResult(result)
      })
    })
  }

  getOptionsByValues(options, values) {
    return options.filter(option => {
      return values.includes(option.value)
    })
  }

  numText(num) {
    return (num !== undefined && typeof num === 'number') ? String(num) : ''
  }
}

export default SearchForm
