import axios from 'axios'

class DataSource {
  search(query) {
    console.log('DataSource#search query:', query)
    return axios.post('api/search', query).then(response => {
      console.log('DataSource#search response:', response)
      return response.data
    })
  }

  getProjects() {
    return axios.get('/api/projects').then(res => {
      const projects = res.data
      console.log('DataSource#getProjects project:', projects)
      return projects
    })
  }

  getSpecimens(projectAperioId) {
    const url = '/api/specimens?projectAperioId=' + projectAperioId
    return axios.get(url).then(res => {
      const specimens = res.data
      console.log('DataSource#getSpecimens result:', specimens)
      return specimens
    })
  }

  getSlides(specimenAperioId) {
    const url = '/api/slides?specimenAperioId=' + specimenAperioId
    return axios.get(url).then(res => {
      const slides = res.data
      console.log('DataSource#getSlides result:', slides)
      return slides
    })
  }
}

let dataSource = null

const getDataSource = () => {
  if (!dataSource) {
    dataSource = new DataSource()
  }
  return dataSource
}

export default getDataSource
