import React from 'react'
import Pagination from 'react-js-pagination'

import getDataSource from 'src/services/DataSource'
import Specimen from './Specimen'
import textUtil from 'src/utils/textUtil'

class Specimens extends React.Component {
  constructor() {
    super()
    this.handlePageChange = pageNumber => {
      const startRow = this.props.state.searchParams.rows * (pageNumber - 1)
      console.log('page:', pageNumber, 'row:', startRow)
      this.props.updateSearchParams('start', startRow)
      setTimeout(() => {
        console.log('SCROLL')
        window.scrollTo(0, 0)
        this.search()
       }, 0)
    }
  }

  render() {
    const searchParams = this.props.state.searchParams
    let specimens = this.getSpecimens()
    const specimenElems = specimens.map(specimen => {
      return <Specimen key={specimen.id} data={specimen}
        search={this.props.state.searchForm}
        history={this.props.history} />
    })
    const page = (searchParams.start / searchParams.rows) + 1
    const searchResult = this.props.state.searchResult
    const numTotal = searchResult.grouped ? searchResult.grouped.specimen_aperio_id.ngroups : 0
    const pagination = numTotal <= searchParams.rows ? '' : (
      <Pagination
        activePage={page}
        itemsCountPerPage={searchParams.rows}
        totalItemsCount={numTotal}
        pageRangeDisplayed={5}
        onChange={this.handlePageChange}
        innerClass='pagination my-4'
        itemClass='page-item'
        linkClass='page-link'
      />
    )

    return (
      <div>
        {pagination}
        {specimenElems}
        {pagination}
      </div>
    )
  }

  // Build "pseudo" specimen data from treatments
  getSpecimens() {
    const result = this.props.state.searchResult
    const specimens = []

    if (result.responseHeader && result.responseHeader.status === 0) {
      for (let group of result.grouped.specimen_aperio_id.groups) {
        // Take the first treatment document to extract data about
        // the specimen, which are stored redundantly in each treatment doc.
        const refDoc = group.doclist.docs[0]
        const hasRecords = refDoc.experimental_records ? 'Yes' : 'No'
        const recordsContent = textUtil.dataToLabel(refDoc.experimental_records_content)

        const sacrificeAge = this.getSacrificeAgeString(refDoc)
        const ordered_thumbnail_urls = this.orderThumbnailUrls(refDoc.ordered_thumbnail_urls)
        const specimen = {
          id: 'specimen_' + refDoc.specimen_aperio_id,
          label: refDoc.specimen_label,
          aperio_id: refDoc.specimen_aperio_id,
          treatments: [],
          sacrifice_age: sacrificeAge,
          gender: textUtil.dataToLabel(refDoc.gender),
          processing_methods: refDoc.processing_methods,
          processing_areas: refDoc.processing_areas,
          ordered_thumbnail_urls: ordered_thumbnail_urls,
          num_slides: refDoc.num_slides,
          experimental_records: hasRecords,
          experimental_records_content: recordsContent
        }
        this.addTreatmentData(specimen, group.doclist.docs)
        specimens.push(specimen)
      }
    }
    return specimens
  }

  addTreatmentData(specimen, treatments) {
    if (treatments.length > 0) {
      specimen.treatments = treatments[0].treatments
    }
  }

  // tdoc: document of "treatment" doc_type from solr
  getSacrificeAgeString(tdoc) {
    const ageType = tdoc.sacrifice_age_type
    const ageValue = tdoc.sacrifice_age_value

    if (ageType == 'adult') {
      return 'adult'
    } else if (ageType == 'p' || ageType == 'e') {
      return ageType + ageValue
    } else if (ageType === undefined) {
      return '';
    } else {
      console.log('ERROR invalid sacrifice_age_type', ageType)
      return ''
    }
  }

  orderThumbnailUrls(thumbnail_urls) {
    if (typeof thumbnail_urls === 'undefined' || thumbnail_urls === null || thumbnail_urls === [] ) {
      return []
    }  
    else {
      const parsed = thumbnail_urls.map(urls => JSON.parse(urls))
      // sort so nulls come last- see: https://stackoverflow.com/questions/43819867/sort-an-array-of-numbers-so-that-null-values-come-last
      const sorted = parsed.sort((a, b) =>
        (b.thumbnail_display_order != null) - (a.thumbnail_display_order!= null) || a.thumbnail_display_order - b.thumbnail_display_order
      )
      const urls = sorted.map( thumbnail => thumbnail.thumbnail_url )
      return urls
    }   
  }

  search() {
    getDataSource().search(this.props.state.searchParams).then(result => {
      this.props.state.receivedSearchResult(result)
    })
  }
}

export default Specimens
