const initialState = {
  specimen: null,
  slides: []
}

const slides = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SLIDES':
      return {
        specimen: { ...action.specimen },
        slides: [ ...action.slides ]
      }
    default:
      return state;
  }
};

export default slides
