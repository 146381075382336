const initialState = {
  treatmentAgeType: 'any',
  sacrificeAgeType: 'any',
  start: 0,
  rows: 10
}

const searchParams = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_PARAMS':
      const newState = { ...state }
      switch (action.key){
        case 'collection':
        case 'treatmentMethod':
        case 'treatmentLocation':
        case 'processingMethod':
        case 'processingArea':
          newState[action.key] = [ ...action.value ]
          break
        case 'treatmentAgeType':
        case 'treatmentAgeMin':
        case 'treatmentAgeMax':
        case 'sacrificeAgeType':
        case 'sacrificeAgeMin':
        case 'sacrificeAgeMax':
        case 'start':
          newState[action.key] = action.value
          break
        default:
          console.error('ERROR reducers/searchParams invalid key', action.key)
      }
      return newState
    case 'UPDATE_SEARCH_PARAMS_MULTI':
      return { ...state, ...action.params }
    case 'RESET_SEARCH_PARAMS':
      return initialState
    default:
      return state
  }
};

export default searchParams
