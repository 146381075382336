export default {
  // Experimental treatment
  "treatment": [
    { "value": "tritiated_thymidine_injection", "label": "Tritiated Thymidine Injection"},
    { "value": "lesion", "label": "Lesion"},
    { "value": "enucleation", "label": "Enucleation"},
    { "value": "tract_tracing_injection", "label": "Tract Tracing Injection"},
    { "value": "irradiation", "label": "Irradiation"},
    { "value": "sham_irradiation", "label": "Sham Irradiation"},
    { "value": "em_blocks", "label": "Em Blocks"},
    { "value": "none", "label": "None"}
  ],
  // Experimental treatment locations
  "location": [
    { "value": "prefrontal_cortex", "label": "Prefrontal Cortex"},
    { "value": "sensorimotor_cortex", "label": "Sensorimotor Cortex"},
    { "value": "parietal_cortex", "label": "Parietal Cortex"},
    { "value": "temporal_cortex", "label": "Temporal Cortex"},
    { "value": "occipital_cortex", "label": "Occipital Cortex"},
    { "value": "striatum", "label": "Striatum"},
    { "value": "thalamus", "label": "Thalamus"},
    { "value": "amygdala", "label": "Amygdala"},
    { "value": "hippocampus", "label": "Hippocampus"},
    { "value": "cerebellum", "label": "Cerebellum"},
    { "value": "brain_stem", "label": "Brain Stem"},
    { "value": "spinal_cord", "label": "Spinal Cord"},
    { "value": "eye", "label": "Eye"},
    { "value": "systemic", "label": "Systemic"}
  ],
  // Histological processing
  "processing": [
    { "value": "nissl", "label": "Nissl"},
    { "value": "golgi", "label": "Golgi"},
    { "value": "immunocytochemistry", "label": "Immunocytochemistry"},
    { "value": "em_blocks", "label": "Em Blocks"},
    { "value": "autoradiography", "label": "Autoradiography"},
    { "value": "unstained", "label": "Unstained"}
  ],
  // Brain area of histological processing
  "area": [
    { "value": "prefrontal_cortex", "label": "Prefrontal Cortex"},
    { "value": "sensorimotor_cortex", "label": "Sensorimotor Cortex"},
    { "value": "parietal_cortex", "label": "Parietal Cortex"},
    { "value": "temporal_cortex", "label": "Temporal Cortex"},
    { "value": "occipital_cortex", "label": "Occipital Cortex"},
    { "value": "striatum", "label": "Striatum"},
    { "value": "thalamus", "label": "Thalamus"},
    { "value": "amygdala", "label": "Amygdala"},
    { "value": "hippocampus", "label": "Hippocampus"},
    { "value": "cerebellum", "label": "Cerebellum"},
    { "value": "brain_stem", "label": "Brain Stem"},
    { "value": "spinal_cord", "label": "Spinal Cord"},
    { "value": "olfactory_bulbs", "label": "Olfactory Bulbs"},
    { "value": "eye", "label": "Eye"}
  ]
}
