import React from 'react'

const Header = () => {
  const rootURL = 'https://medicine.yale.edu'
  return (
    <header>
      <nav className="topper">
      <div className="container">
          <div className="row">
            <div className="col topper-breadcrumb">
              <a href={rootURL}>Yale School of Medicine</a>
              <a href={`${rootURL}/neuroscience/`}>Neuroscience Search</a>
            </div>
          </div>
        </div>
      </nav>
      <div className="page-header container-fluid">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="text-white text-header"><a href="/">MacBrainResource Center (MBRC)</a></p>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
