// Search result - specimens
const searchResult = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_RESULT':
      return { ...action.searchResult }
    default:
      return state
  }
};

export default searchResult
