const initialState = {
  type: null,  // null | 'info' | 'error'
  message: '',
  expireInMS: null
}

const alertPanel = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_ALERT':
      return {
        type: action.alert.type,
        message: action.alert.message,
        expireInMS: action.alert.expireInMS
      }
    case 'RESET_ALERT':
      return { ...initialState }
    default:
      return state;
  }
};

export default alertPanel
