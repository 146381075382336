import React from 'react'

const NumFound = props => {
  const result = props.state.searchResult
  let numFound = 0
  let msg = 'No results are found for your search criteria.'

  if (result && result.grouped) {
    numFound = result.grouped.specimen_aperio_id.ngroups
  }
  if (numFound > 0) {
    msg = `Found ${numFound} case${numFound > 1 ? 's' : ''}`
  }
  return (
    <div className="row mb-3">
      <div className="col">
        {msg}
      </div>
    </div>
  )
}

export default NumFound
