import React from 'react'
import { Link } from 'react-router-dom'

import helper from 'src/utils/treatmentHelper'
import textUtil from 'src/utils/textUtil'

class Specimen extends React.Component {
  render() {
    const specimen = this.props.data
    const title = specimen.label || specimen.id
    const treatments = helper.buildTreatmentsSection(specimen)
    const brainAreas = helper.buildBrainAreasString(specimen)
    const slidesURL = `/specimens/${specimen.aperio_id}/slides`
    const thumbnailURLs = specimen.ordered_thumbnail_urls || []
    const thumbnails = thumbnailURLs.map(url =>
      <img key={url} src={url} className="small-thumb"/>
    )
    const dots = specimen.num_slides > 5 ? <span className="thumb-dots">...</span> : ''

    const slidesLink = specimen.num_slides > 0 ?
      <Link to={slidesURL} className="card-link">View slides</Link> : ''
    return (
      <div className="specimen card mb-3 macbrain-card">
          <div className="card-body">
            <h3 className="card-title">{title}</h3>
            <div className="row">
              <div className="col-lg-6 mb-2">
              {treatments}
              <p className="card-text">
                <span className="label">Brain area(s):</span> {brainAreas}
              </p>
              <p className="card-text">
                <span className="label">Gender:</span> {specimen.gender}<br/>
                <span className="label">Age at sac:</span> {textUtil.capFirst(specimen.sacrifice_age)}<br/>
              </p>
              <p className="card-text">
                <span className="label">Experimental records:</span> {String(specimen.experimental_records)}<br/>
                <span className="label">Records content:</span> {specimen.experimental_records_content}
              </p>
            </div>
            <div className="col-lg-6">
              <p className="card-text">
                {thumbnails}
                {dots}
                <br/>
                {slidesLink}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Specimen
