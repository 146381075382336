import React from 'react'
import { Link } from 'react-router-dom'

import Alert from './Alert'
import getDataSource from 'src/services/DataSource'
import helper from 'src/utils/treatmentHelper'
import RequestAccessLink from './RequestAccessLink'
import SlideTabs from './SlideTabs'

class SlidesPage extends React.Component {
  componentDidMount() {
    const specimenAperioId = this.props.match.params.id
    getDataSource().getSlides(specimenAperioId).then(result => {
      this.props.receivedSlides(result.specimen, result.slides)
    })
  }

  render() {
    const pageState = this.props.state.slides
    const treatments = helper.buildTreatmentsSection(pageState.specimen)
    const title = pageState.specimen ? `Slides in ${pageState.specimen.label}` : ''
    const slides = pageState.slides
    return (
      <div className='container'>
        <Alert className="mt-3" state={this.props.state.alert}
          resetAlert={this.props.resetAlert}
        />
        <div className='row my-4'>
          <div className='col' style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
            <div style={{flex: 'auto'}}>
              <Link to="/"><i className="fas fa-caret-left mr-1" style={{fontSize: 16, verticalAlign: -1}}/>Back to search</Link>
            </div>
          </div>
          <div className='col' style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
            <div style={{flex: 'auto'}}>
              <RequestAccessLink resetAlert={this.props.resetAlert}/>
            </div>
          </div>
        </div>
        <h2 className="my-4">{title}</h2>
        {treatments}
        <div className='row'>
          <SlideTabs state={this.props.state} slides={slides} specimen={pageState.specimen} />
        </div>
      </div>
    )
  }
}

export default SlidesPage
