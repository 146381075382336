import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import SearchPage from './SearchPage'
import SlidesPage from './SlidesPage'
import Request from './Request'

export default class App extends React.Component {
  render() {
    console.log('App props:', this.props)
    const state = this.props
    return (
      <BrowserRouter>
        <div>
          <Header/>
          <Route exact path='/'
            render={props =>
              <SearchPage {...props} state={state}
                updateSearchParams={this.props.updateSearchParams}
                resetSearchParams={this.props.resetSearchParams}
                updateProjects={this.props.updateProjects}
                receivedSearchResult={this.props.receivedSearchResult}
                updateAlert={this.props.updateAlert}
                resetAlert={this.props.resetAlert}
              />
            }
          />
          <Route path='/specimens/:id/slides'
            render={props =>
              <SlidesPage {...props} state={state}
                receivedSlides={this.props.receivedSlides}
                updateAlert={this.props.updateAlert}
                resetAlert={this.props.resetAlert}
              />
            }
          />
          <Route path='/request'
            render={props =>
              <Request {...props} state={state}
                updateRequestParams={this.props.updateRequestParams}
                resetRequestParams={this.props.resetRequestParams}
                clearRequestParams={this.props.clearRequestParams}
                updateAlert={this.props.updateAlert}
                resetAlert={this.props.resetAlert}
              />
            }
          />
          <Footer/>
        </div>
      </BrowserRouter>
    )
  }
}
