// Capitalize the first letter of the string
const textUtil = {
  capFirst: s => {
    if (typeof s === 'string' && s.length > 0) {
      return s[0].toUpperCase() + s.slice(1)
    } else {
      return s
    }
  },

  // e.g. "prefrontal_cortex" => "Prefrontal Cortex"
  dataToLabel: s => {
    if (typeof s !== 'string') {
      return ''
    } else if (s == 'na') {
      return 'NA'
    } else {
      return s.split('_').map(word => textUtil.capFirst(word)).join(' ')
    }
  }
}

export default textUtil
