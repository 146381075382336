import React from 'react'

import textUtil from './textUtil'

export default {
  buildTreatmentsSection: specimen => {
    if (specimen === undefined || specimen === null) {
      return ''
    }

    const treatments = specimen.treatments || []
    const result = []

    for (let i = 0; i < treatments.length; ++i) {
      const [method, age, location] = treatments[i].split('|').map(s => {
        return textUtil.dataToLabel(s)
      })
      result.push(
        <p key={'treatment-' + i}>
          <span className="label">Experimental treatment:</span> {method}<br/>
          <span className="label">Age at treatment:</span> {age}<br/>
          <span className="label">Location of treatment:</span> {location}
        </p>
      )
    }
    return result
  },

  buildBrainAreasString: specimen => {
    const areas = specimen.processing_areas || []
    return areas.map(area => {
      return textUtil.dataToLabel(area)
    }).join(', ')
  }
}
