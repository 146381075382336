import React from 'react'

const Footer = () => {
  const rootURL = 'https://medicine.yale.edu'
  return (
    <footer className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="footer-container container">
            <div className="footer-wrapper">
              <section className="footer-logo-container">
                <img src="https://cdn1.medicine.yale.edu/static/images/ysm_shield.svg" alt="Yale School of Medicine Shield" className="footer-logo"/>
                  <section className="footer-logo-copy">
                      <span>Education</span>
                      <span>Patient Care</span>
                      <span>Research</span>
                  </section>
              </section>
              <section className="footer-address">
                <div itemScope="" itemType="http://schema.org/Organization">
                  <span itemProp="name">MacBrainResource Center (MBRC)</span>
                  <div itemProp="address" itemScope="" itemType="http://schema.org/PostalAddress">
                    <span itemProp="streetAddress">PO Box 208001</span><br/>
                    <span itemProp="addressLocality">New Haven</span>
                    <span itemProp="addressRegion">CT</span><br/>
                    <span itemProp="postalCode">06520-8001</span><br/>
                    <span itemProp="email">macbrainresource@yale.edu</span>
                  </div>
                </div>
              </section>
              <section className="footer-navigation">
                <div className="footer-links">
                  <a href={`${rootURL}/neuroscience/macbrain/privacy.aspx`}>Terms &amp; Privacy Policies</a>
                  <a href={`${rootURL}/web/policies/accessibility/`}>Accessibility at YSM</a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
