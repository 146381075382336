import React from 'react'

import NumFound from './NumFound'
import RequestAccessLink from './RequestAccessLink'
import SearchForm from './SearchForm'
import Specimens from './Specimens'
import Alert from './Alert'

class SearchPage extends React.Component {
  render() {
    console.log('SearchPage props:', this.props)

    return (
      <div className='container'>
        <Alert className="mt-3" state={this.props.state.alert}
          resetAlert={this.props.resetAlert}
        />
        <div className='back-to-site row my-3'>
          <div className='col' style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
            <div className='my-1' style={{flex: 'auto'}}>
              <a href="https://medicine.yale.edu/neuroscience/macbrain" style={{whiteSpace: 'nowrap'}}>
                <i className="fas fa-caret-left mr-1" style={{fontSize: 16, verticalAlign: -1}}/>Back to MacBrainResource Center (MBRC) site
              </a>
            </div>
            <div className='my-1' style={{flex: 'auto'}}>
              <RequestAccessLink resetAlert={this.props.resetAlert}/>
            </div>
          </div>
        </div>
        <SearchForm state={this.props.state}
          search={this.props.location.search}
          updateSearchParams={this.props.updateSearchParams}
          resetSearchParams={this.props.resetSearchParams}
          updateProjects={this.props.updateProjects}
          resetAlert={this.props.resetAlert}
        />
        <NumFound state={this.props.state}/>
        <Specimens state={this.props.state}
          history={this.props.history}
          updateSearchParams={this.props.updateSearchParams}
        />
      </div>
    )
  }
}

export default SearchPage
