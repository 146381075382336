import { combineReducers } from 'redux'

import projects from './projects'
import requestMail from './requestMail'
import slides from './slides'
import searchResult from './searchResult'
import searchParams from './searchParams'
import alertPanel from './alert'

const rootReducer = combineReducers({
  projects,
  searchParams,
  searchResult,
  slides,
  requestMail,
  alert: alertPanel
});

export default rootReducer;
