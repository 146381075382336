import React from 'react'

const Alert = props => {
  const state = props.state
  let style = {}
  let classes = ['macbrain-alert']

  if (props.className) {
    classes = classes.concat(props.className.split(/\s+/))
  }

  if (state.type === 'info') {
    classes.push('info')
  } else if (state.type === 'error') {
    classes.push('error')
  } else {
    style = { display: 'none' }
  }

  if (state.expireInMS && props.resetAlert) {
    setTimeout(() => {
      props.resetAlert(null, '', null)
    }, state.expireInMS)
  }

  const icon = state.type === 'error' ?
    <i className="fas fa-exclamation-circle"></i> :
    <i className="fas fa-check-circle"></i>

  return (
    <div className={classes.join(' ')} style={style}>
      {icon}
      {state.message}
    </div>
  )
}

export default Alert
