import React from 'react'

import Slide from './Slide'

class Slides extends React.Component {
  render() {
    const slides = this.props.slides
    const slideElems = slides.map(slide => (
      <Slide key={slide.id} slide={slide} />
    ))
    return slideElems
  }
}

export default Slides
