import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'bootstrap/dist/js/bootstrap'
import '../stylesheets/application'
import '@fortawesome/fontawesome-free/css/all'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'

import AppContainer from 'src/containers/AppContainer'
import rootReducer from 'src/reducers'
import { globalInit } from 'src/services/init'

console.log('NODE_ENV:', process.env.NODE_ENV)

let store

if (process.env.NODE_ENV === 'production') {
  store = createStore(rootReducer)
} else {
  store = createStore(
    rootReducer,
    // set up for chrome redux dev tools extension
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
}

document.addEventListener('DOMContentLoaded', () => {
  globalInit();
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer />
    </Provider>,
    document.body.appendChild(document.createElement('div'))
  )
})
