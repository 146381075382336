export const updateSearchParams = (key, value) => {
  console.log('actions/updateSearchParams key:', key, 'value:', value)
  if (typeof key === 'string') {
    return {
      type: 'UPDATE_SEARCH_PARAMS',
      key,
      value
    }
  } else if (typeof key === 'object') {
    return {
      type: 'UPDATE_SEARCH_PARAMS_MULTI',
      params: key
    }
  } else {
    console.log('ERROR updateSearchParams invalid arg', key)
  }
}

export const resetSearchParams = () => {
  console.log('actions/resetSearchParams')
  return {
    type: 'RESET_SEARCH_PARAMS'
  }
}

export const updateSearchResult = searchResult => {
  console.log('actions/updateSearchResult searchResult:', searchResult)
  return {
    type: 'UPDATE_SEARCH_RESULT',
    searchResult
  }
}

export const updateProjects = projects => {
  console.log('actions/updateProjects projects:', projects)
  return {
    type: 'UPDATE_PROJECTS',
    projects
  }
}

export const updateSlides = (specimen, slides) => {
  console.log('actions/browseSlides specimen:', specimen, 'slides:', slides)
  return {
    type: 'UPDATE_SLIDES',
    specimen,
    slides
  }
}

export const updateRequestParams = (key, value) => {
  console.log('actions/updateRequestParams key:', key, 'value:', value)
  return {
    type: 'UPDATE_REQUEST_PARAMS',
    key,
    value
  }
}

export const resetRequestParams = () => {
  console.log('actions/resetRequestParams')
  return {
    type: 'RESET_REQUEST_PARAMS'
  }
}

export const updateAlert = (type, message, expireInMS) => {
  console.log('actions/updateAlert type:', type, 'message:', message, 'expireInMS:', expireInMS)
  return {
    type: 'UPDATE_ALERT',
    alert: {
      type,
      message,
      expireInMS
    }
  }
}

export const resetAlert = () => {
  return {
    type: 'RESET_ALERT'
  }
}
